































































import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root }) {
    const state: {
      startDate: string
      startTime: string
      finishDate: string
      finishTime: string
      startDateMenu: boolean
      startTimeMenu: boolean
      finishDateMenu: boolean
      finishTimeMenu: boolean
      req: {
        startDate?: Date
        finishDate?: Date
        name: string
        code: string
        fish: number
      }
    } = reactive({
      startDate: '',
      startTime: '',
      finishDate: '',
      finishTime: '',
      startDateMenu: false,
      startTimeMenu: false,
      finishDateMenu: false,
      finishTimeMenu: false,
      req: {
        name: '',
        code: '',
        fish: 0,
      },
    })

    let arr: Array<string | number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    arr = arr.concat(Array.from({ length: 26 }, (v, i) => String.fromCharCode(i + 65)))

    const createCode = () => {
      state.req.code = ''
      for (let index = 0; index < 10; index++) {
        state.req.code += arr[Math.floor(Math.random() * arr.length)]
      }
    }

    const getOneCoupon = () => {
      root.$store
        .dispatch('coupon/getOneCoupon', { id: Number(root.$route.params.uid) })
        .then((res) => {
          const startDate = new Date(res.startDate)
          const sY = String(startDate.getFullYear())
          const sM = String(startDate.getMonth() + 1).padStart(2, '0')
          const sD = String(startDate.getDate()).padStart(2, '0')
          const shh = String(startDate.getHours()).padStart(2, '0')
          const smm = String(startDate.getMinutes()).padStart(2, '0')
          state.startDate = sY + '-' + sM + '-' + sD
          state.startTime = shh + ':' + smm
          const finishDate = new Date(res.finishDate)
          const fY = String(finishDate.getFullYear())
          const fM = String(finishDate.getMonth() + 1).padStart(2, '0')
          const fD = String(finishDate.getDate()).padStart(2, '0')
          const fhh = String(finishDate.getHours()).padStart(2, '0')
          const fmm = String(finishDate.getMinutes()).padStart(2, '0')
          state.finishDate = fY + '-' + fM + '-' + fD
          state.finishTime = fhh + ':' + fmm
          state.req.name = res.name
          state.req.code = res.code
          state.req.fish = res.fish
        })
        .catch((err) => {
          alert(err)
          console.log(err)
        })
    }

    const createCoupon = async () => {
      try {
        state.req.startDate = new Date(state.startDate + 'T' + state.startTime)
        state.req.finishDate = new Date(state.finishDate + 'T' + state.finishTime)
        state.req.fish = Number(state.req.fish)
        await root.$store.dispatch('coupon/createCoupon', state.req)

        root.$router.push('/coupon')
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    const updateCoupon = async () => {
      try {
        state.req.startDate = new Date(state.startDate + 'T' + state.startTime)
        state.req.finishDate = new Date(state.finishDate + 'T' + state.finishTime)
        state.req.fish = Number(state.req.fish)
        await root.$store.dispatch('coupon/updateCoupon', {
          id: Number(root.$route.params.uid),
          ...state.req,
        })

        root.$router.push('/coupon')
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    onBeforeMount(() => {
      if (root.$route.params.uid) getOneCoupon()
    })

    return {
      ...toRefs(state),
      createCode,
      createCoupon,
      updateCoupon,
    }
  },
})
